export const findDatePrevious = (newDate = Date(), substractYear) => {
  let res = new Date(
    newDate.setFullYear(newDate.getFullYear() - substractYear)
  );
  return res.toISOString().substr(0, 10);
};

export const preventdot = (event) => {
  if (event.key === "e" || event.key === ".") {
    event.preventDefault(); // Prevent typing 'e' and '.'
  }
};
